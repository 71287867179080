import createMapUtils from './maps-utils';
import createMarkersAPI from './markers-api';

const POI_MARKER_ID = 'pointOfInterest';

export class PoiAPI {
  constructor(map, opts = {}) {
    const {
      markerIcon,
      circleColor,
      circleRadius,
      onMarkerClick,
      onMarkerMouseOver,
      onMarkerMouseOut,
      labelClasses
    } = opts;

    this.markerIcon = markerIcon;
    this.circleColor = circleColor;
    this.circleRadius = circleRadius;
    this.poiBoundaries = null;
    this.map = map;
    this.labelClasses = labelClasses;

    this.gMaps = createMapUtils();
    this.circles = {};

    this.markersAPI = createMarkersAPI(this.map, {
      markerIcon,
      onMarkerClick,
      onMarkerMouseOver,
      onMarkerMouseOut,
      labelClasses: this.labelClasses
    });
  }

  getPoiBoundaries = () => {
    return this.poiBoundaries;
  };

  addPointOfInterest = ({ lat, lng } = {}) => {
    if (lat && lng) {
      this.poiBoundaries = {
        lat,
        lng
      };

      this.markersAPI.deleteMarker(POI_MARKER_ID);

      this.markersAPI.addMarker(POI_MARKER_ID, {
        lat,
        lng,
        silent: true
      });

      this.addCircle(POI_MARKER_ID, {
        lat: parseFloat(lat),
        lng: parseFloat(lng),
        color: this.circleColor,
        radius: this.circleRadius
      });
    }
  };

  removePointOfInterest = () => {
    this.markersAPI.removeMarker(POI_MARKER_ID);
    this.markersAPI.deleteMarker(POI_MARKER_ID);
    this.removeCircle(POI_MARKER_ID);
    this.poiBoundaries = null;
  };

  // refactor if it really is the POI
  addCircle = (circleId, opts) => {
    if (this.circles[circleId]) {
      this.removeCircle(circleId);
    }

    this.circles[circleId] = this.gMaps.createCircle({
      strokeColor: opts.color,
      strokeOpacity: 1,
      strokeWeight: 1,
      fillColor: opts.color,
      fillOpacity: 0.25,
      map: this.map,
      center: {
        lat: parseFloat(opts.lat),
        lng: parseFloat(opts.lng)
      },
      radius: opts.radius
    });
  };

  removeCircle = circleId => {
    if (this.circles[circleId]) {
      this.circles[circleId].setMap(null);
      delete this.circles[circleId];
    }
  };
}

const createPoiAPI = (map, options) => {
  const poiAPI = new PoiAPI(map, options);

  return {
    addPointOfInterest: poiAPI.addPointOfInterest,
    removePointOfInterest: poiAPI.removePointOfInterest,
    getPoiBoundaries: poiAPI.getPoiBoundaries
  };
};

export default createPoiAPI;
