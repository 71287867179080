import Quadtree from '@timohausmann/quadtree-js';

import createMapUtils from './maps-utils';
import createMarkersAPI from './markers-api';

const NO_OP = () => null;

const QUADRANT_CENTER_WEIGHT = 0.2; // From 0 to 1. Weight of the center of the quadrant to define the coordinates of its cluster
const QUADTREE_MAX_OBJECTS = 1; // It defines how many objects a node can hold before it splits

const CLUSTER_ID_SEPARATOR = ';';
const CLUSTER_MAX_WIDTH = 32;
const CLUSTER_MARGIN = 32;
const CLUSTER_MIN_SIZE = 8;
const CLUSTER_MAX_ZOOM = 17;

export class ClustersAPI {
  constructor(map, opts = {}) {
    this.map = map;
    this.clusters = {};
    this.gMaps = createMapUtils();

    const {
      onMarkerClick,
      onMarkerMouseOver,
      onMarkerMouseOut,
      onClusterClick,
      onClusterMouseOver,
      onClusterMouseOut,
      getMarkerText
    } = opts;

    this.onMarkerClick = onMarkerClick || NO_OP;
    this.onClusterClick = onClusterClick || NO_OP;
    this.onClusterMouseOver = onClusterMouseOver || NO_OP;
    this.onClusterMouseOut = onClusterMouseOut || NO_OP;
    this.onMarkerMouseOver = onMarkerMouseOver || NO_OP;
    this.onMarkerMouseOut = onMarkerMouseOut || NO_OP;

    this.getMarkerText = markerId => {
      if (this.isCluster(markerId)) {
        const cluster = this.clusters[markerId];
        const clusterSize = cluster.node.objects.length;
        return `${clusterSize}`;
      }
      return getMarkerText(markerId);
    };
    this.markersAPI = createMarkersAPI(this.map, {
      onMarkerClick: this.handleMarkerClick,
      onMarkerMouseOver: this.handleMarkerMouseOver,
      onMarkerMouseOut: this.handleMarkerMouseOut,
      getMarkerStyle: this.getMarkerStyle,
      getMarkerText: this.getMarkerText,
      getMarkerColor: this.getMarkerColor
    });
  }

  getClusterStyleAndText = clusterSize => ({
    text: `${clusterSize}`,
    style: this.getClusterMarkerStyle(clusterSize)
  });

  getMarkerStyle = markerId => {
    if (this.isCluster(markerId)) {
      const cluster = this.clusters[markerId];
      if (cluster) {
        const clusterSize = cluster.node.objects.length;
        return this.getClusterMarkerStyle(clusterSize);
      }
    }
    return {};
  };

  getMaxZoom = () => CLUSTER_MAX_ZOOM;

  handleMarkerClick = markerId => {
    if (this.isCluster(markerId)) {
      const nodeClicked = this.clusters[markerId].node;
      const coordinates = this.getClusterCoordinates(nodeClicked);
      this.onClusterClick(coordinates);
    } else {
      this.onMarkerClick(markerId);
    }
  };

  handleMarkerMouseOver = markerId => {
    if (this.isCluster(markerId)) {
      this.onClusterMouseOver(markerId);
    } else {
      this.onMarkerMouseOver(markerId);
    }
  };

  handleMarkerMouseOut = markerId => {
    if (this.isCluster(markerId)) {
      this.onClusterMouseOut(markerId);
    } else {
      this.onMarkerMouseOut(markerId);
    }
  };

  isCluster = itemId =>
    typeof itemId === 'string' && itemId.includes(CLUSTER_ID_SEPARATOR); // cluster id is a string, marker id is a number

  removeMarkers = (markerIds = []) => {
    this.markersAPI.removeMarkers(markerIds, { deleteMarker: true });
  };

  removeMarker = markerId => {
    this.markersAPI.removeMarker(markerId);
  };

  removeAllMarkers = () => {
    this.markersAPI.removeAllMarkers();
  };

  getClusterMarkerScale = numberOfNodes => {
    const scale =
      (numberOfNodes < 20 && CLUSTER_MAX_WIDTH - 16) ||
      (numberOfNodes < 50 && CLUSTER_MAX_WIDTH - 12) ||
      (numberOfNodes < 100 && CLUSTER_MAX_WIDTH - 8) ||
      CLUSTER_MAX_WIDTH;

    return scale;
  };

  getClusterMarkerStyle = numberOfNodes => {
    const fontSize =
      (numberOfNodes < 20 && '14px') ||
      (numberOfNodes < 50 && '16px') ||
      (numberOfNodes < 100 && '18px') ||
      '20px';

    return {
      fontSize
    };
  };

  getClusterCenter = bounds => ({
    x: bounds.x + bounds.width / 2,
    y: bounds.y + bounds.height / 2
  });
  getNodesAveragePosition = nodes => {
    const { x: accX, y: accY } = nodes.reduce(
      (acc, { x, y }) => ({ x: acc.x + x, y: acc.y + y }),
      { x: 0, y: 0 }
    );
    return {
      x: accX / nodes.length,
      y: accY / nodes.length
    };
  };

  getClusterCoordinates = ({ objects, bounds }) => {
    const { x: avgX, y: avgY } = this.getNodesAveragePosition(objects);
    const { x: ctrX, y: ctrY } = this.getClusterCenter(bounds);

    return {
      lat: avgY * (1 - QUADRANT_CENTER_WEIGHT) + ctrY * QUADRANT_CENTER_WEIGHT,
      lng: avgX * (1 - QUADRANT_CENTER_WEIGHT) + ctrX * QUADRANT_CENTER_WEIGHT
    };
  };

  renderCluster = node => {
    const currentMapZoom = this.map.getZoom();
    if (
      node.objects.length < CLUSTER_MIN_SIZE ||
      currentMapZoom >= CLUSTER_MAX_ZOOM
    ) {
      node.objects.forEach(marker => {
        const id = marker.id;
        this.markersAPI.addMarker(id, {
          lat: marker.y,
          lng: marker.x,
          text: this.getMarkerText(id)
        });
      });
    } else if (node.objects.length) {
      const { lat, lng } = this.getClusterCoordinates(node);
      const id = `${lat}${CLUSTER_ID_SEPARATOR}${lng}`;
      this.clusters[id] = { id, node };
      const { style, text } = this.getClusterStyleAndText(node.objects.length);
      this.markersAPI.addMarker(id, {
        lat,
        lng,
        text,
        style
      });
    }
  };

  refreshMinMaxCoordinates = (markers, bounds = false) => {
    if (bounds) {
      this.minLat = bounds.southWest.lat;
      this.maxLat = bounds.northEast.lat;
      this.minLong = bounds.southWest.lng;
      this.maxLong = bounds.northEast.lng;
      return;
    }
    const { minLat, maxLat, minLong, maxLong } = markers.reduce(
      (acc, marker) => ({
        minLat: Math.min(acc.minLat, marker.coord[1]),
        maxLat: Math.max(acc.maxLat, marker.coord[1]),
        minLong: Math.min(acc.minLong, marker.coord[0]),
        maxLong: Math.max(acc.maxLong, marker.coord[0])
      }),
      { minLat: 180, maxLat: -180, minLong: 180, maxLong: -180 }
    );
    this.minLat = minLat;
    this.maxLat = maxLat;
    this.minLong = minLong;
    this.maxLong = maxLong;
  };

  getMaxLevelsFromMapDimensions = mapDimensions => {
    const { width, height } = mapDimensions;
    const mapSize = Math.min(width, height);
    const maxColumns = Math.floor(
      mapSize / (CLUSTER_MAX_WIDTH + CLUSTER_MARGIN)
    );
    const maxLevels = Math.log(maxColumns) / Math.log(2); // Power of 2 that is equal to maxColumns
    return Math.floor(maxLevels); // Higher integer power of two lower than maxColumns
  };

  addMarkers = (markers, mapDimensions, mapBounds) => {
    Object.keys(this.clusters).forEach(clusterId => {
      this.removeMarker(clusterId);
    });
    this.removeAllMarkers();
    this.refreshMinMaxCoordinates(markers, mapBounds);

    const width = this.maxLong - this.minLong;
    const height = this.maxLat - this.minLat;

    const myTree = new Quadtree(
      { x: this.minLong, y: this.minLat, width, height },
      QUADTREE_MAX_OBJECTS,
      this.getMaxLevelsFromMapDimensions(mapDimensions)
    );

    markers.forEach(marker => {
      myTree.insert({
        x: marker.coord[0],
        y: marker.coord[1],
        width: 0.0000000001,
        height: 0.0000000001,
        id: marker.id,
        minimumPrice: marker.minimumPrice
      });
    });

    const renderClusters = node => {
      if (node.nodes.length) {
        node.nodes.forEach(renderClusters);
      } else {
        this.renderCluster(node);
      }
    };

    renderClusters(myTree);
  };

  getClustersBoundaries = () => {
    const mapsBoundaries = this.gMaps.createLatLngBounds();
    mapsBoundaries.extend(this.gMaps.createLatLng(this.minLat, this.minLong));
    mapsBoundaries.extend(this.gMaps.createLatLng(this.maxLat, this.maxLong));
    return mapsBoundaries;
  };
}

const createClustersAPI = (map, options) => {
  const clustersAPI = new ClustersAPI(map, options);

  return {
    addMarkers: clustersAPI.addMarkers,
    removeMarker: clustersAPI.removeMarker,
    removeMarkers: clustersAPI.removeMarkers,
    removeAllMarkers: clustersAPI.removeAllMarkers,
    getClustersBoundaries: clustersAPI.getClustersBoundaries,
    getMaxZoom: clustersAPI.getMaxZoom
  };
};

export default createClustersAPI;
