import createMapUtils from './maps-utils';

export class Marker {
  constructor(
    map,
    { lat, lng, text, zIndex, className, markerIcon, isBooked }
  ) {
    this.gMaps = createMapUtils();
    this.markerIcon = markerIcon;

    const markerOpts = {
      icon: this.markerIcon,
      position: {
        lat: parseFloat(lat),
        lng: parseFloat(lng)
      },
      label: text
        ? {
            text,
            className
          }
        : null,
      map,
      zIndex
    };

    this.isBooked = isBooked;
    this.baseZIndex = zIndex;
    this.visible = true;
    this.googleMarker = this.gMaps.createMarker(markerOpts);
    this.map = map;

    if (this.isBooked) {
      this.googleMarker.setCursor('default');
    }
  }

  addListener = (eventName, callback) => {
    this.gMaps.clearListeners(this.googleMarker, eventName);
    this.gMaps.addListener(this.googleMarker, eventName, callback);
  };

  isVisible = () => this.visible;

  remove = () => {
    this.gMaps.clearInstanceListeners(this.googleMarker);
    this.visible = false;
    this.googleMarker.setMap(null);
  };

  display = () => {
    this.visible = true;
    this.googleMarker.setMap(this.map);
  };

  updateIcon = ({ className, zIndex = this.baseZIndex }) => {
    this.googleMarker.setZIndex(zIndex);
    this.googleMarker.setIcon(this.markerIcon);

    if (
      this.googleMarker.label &&
      (this.googleMarker.label.text || this.googleMarker.label.text === '')
    ) {
      this.googleMarker.setLabel({
        className,
        text: this.googleMarker.label.text
      });
    }
  };

  getBoundaries = () => this.googleMarker.getPosition();
}

const createMarker = (map, opts) => new Marker(map, opts);

export default createMarker;
