import createMarker from './Marker';

const HOVER_MARKER_ZINDEX_OFFSET = 3000;

const NO_OP = () => null;

export class MarkersAPI {
  constructor(map, opts = {}) {
    const {
      onMarkerClick,
      onMarkerMouseOver,
      onMarkerMouseOut,
      getMarkerText,
      labelClasses,
      markerIcon
    } = opts;

    this.map = map;
    this.markers = {};
    this.labelClasses = labelClasses;
    this.markerIcon = markerIcon;

    this.onMarkerClick = onMarkerClick || NO_OP;
    this.onMarkerMouseOver = onMarkerMouseOver || NO_OP;
    this.onMarkerMouseOut = onMarkerMouseOut || NO_OP;
    this.getMarkerText = getMarkerText || NO_OP;

    this.hoverZIndex = HOVER_MARKER_ZINDEX_OFFSET;
    this.hoveredMarker = null;
    this.clickedMarker = null;
  }

  updateMarkerIcons = opts => {
    const { markerIcon, markerHighlightedIcon } = opts;

    this.markerIcon = markerIcon;
    this.markerHighlightedIcon = markerHighlightedIcon;
  };

  addMarkers = (markerList = []) => {
    markerList.forEach(marker => {
      this.addMarker(marker.id, {
        lat: marker.coord[1],
        lng: marker.coord[0],
        silent: marker.silent || marker.so,
        isBooked: marker.so,
        text: marker.label
          ? marker.label
          : this.getMarkerText(marker.id, marker)
      });
    });

    this.hoverZIndex = Math.max(
      this.hoverZIndex,
      markerList.length + HOVER_MARKER_ZINDEX_OFFSET
    );
  };

  getZIndex = () => Object.keys(this.markers).length + 1;

  addMarker = (markerId, opts) => {
    const { lat, lng, text, silent = false, isBooked } = opts;

    if (!this.markers[markerId]) {
      this.markers[markerId] = createMarker(this.map, {
        lat,
        lng,
        className: isBooked
          ? `${this.labelClasses.default} ${this.labelClasses.booked}`
          : this.labelClasses.default,
        text,
        zIndex: this.getZIndex(),
        markerIcon: this.markerIcon,
        isBooked
      });
    } else {
      this.markers[markerId].display();
    }

    if (!silent) {
      this.setMarkerListeners(markerId);
    }
  };

  removeAllMarkers = () => {
    Object.keys(this.markers).forEach(markerId => {
      this.removeMarker(markerId);
    });
  };

  removeMarkers = (markerIds = [], { deleteMarker = false } = {}) => {
    markerIds.forEach(markerId => {
      this.removeMarker(markerId);
      if (deleteMarker) {
        this.deleteMarker(markerId);
      }
    });
  };

  removeMarker = markerId => {
    this.markers[markerId]?.remove();
  };

  deleteMarker = markerId => {
    delete this.markers[markerId];
  };

  setDefaultIcon = markerId => {
    const currentMarker = this.markers[markerId];
    if (currentMarker?.isVisible()) {
      currentMarker.updateIcon({
        className: this.labelClasses.default
      });
    }
  };

  setHighlightedIcon = (markerId, action) => {
    if (this.highlightedMarkerId) {
      this.setDefaultIcon(this.highlightedMarkerId);
    }
    const currentMarker = this.markers[markerId];
    if (currentMarker?.isVisible()) {
      currentMarker.updateIcon({
        className: `${this.labelClasses.default} ${
          action !== undefined ? this.labelClasses[action] : ''
        }`,
        zIndex: this.hoverZIndex
      });
      this.highlightedMarkerId = markerId;
    }
  };

  handleMarkerMouseEnter = markerId => {
    this.setHighlightedIcon(markerId, 'hover');
    this.hoveredMarker = markerId;
  };

  handleMarkerMouseOut = markerId => {
    if (markerId === this.clickedMarker) {
      return;
    }
    this.setDefaultIcon(markerId);
    this.hoveredMarker = null;
  };

  handleMarkerClick = markerId => {
    this.setDefaultIcon(this.clickedMarker);
    if (this.clickedMarker !== markerId) {
      this.setHighlightedIcon(markerId, 'active');
      this.clickedMarker = markerId;
    } else {
      this.clickedMarker = null;
    }
  };

  setMarkerListeners = markerId => {
    const currentMarker = this.markers[markerId];
    currentMarker.addListener('click', () => {
      this.handleMarkerClick(markerId);
      this.onMarkerClick(markerId);
    });
    currentMarker.addListener('mouseover', () => {
      this.handleMarkerMouseEnter(markerId);
      this.onMarkerMouseOver(markerId);
    });
    currentMarker.addListener('mouseout', () => {
      this.handleMarkerMouseOut(markerId);
      this.onMarkerMouseOut(markerId);
    });
  };

  getMarkersBoundaries = () =>
    Object.values(this.markers).map(marker => marker.getBoundaries());
}

const createMarkersAPI = (map, options) => {
  const markersAPI = new MarkersAPI(map, options);

  return {
    addMarker: markersAPI.addMarker,
    addMarkers: markersAPI.addMarkers,
    removeMarker: markersAPI.removeMarker,
    removeMarkers: markersAPI.removeMarkers,
    deleteMarker: markersAPI.deleteMarker,
    removeAllMarkers: markersAPI.removeAllMarkers,
    setHighlightedIcon: markersAPI.setHighlightedIcon,
    getMarkersBoundaries: markersAPI.getMarkersBoundaries,
    getMarkerColor: markersAPI.getMarkerColor
  };
};

export default createMarkersAPI;
