const createMapUtils = () => {
  const gMaps = window.google.maps;

  const createMap = (...args) => new gMaps.Map(...args);
  const createLayer = () => new gMaps.TransitLayer();
  const createLatLngBounds = () => new gMaps.LatLngBounds();
  const createLatLng = (...args) => new gMaps.LatLng(...args);
  const createMarker = (...args) => new gMaps.Marker(...args);

  const clearInstanceListeners = (...args) =>
    gMaps.event.clearInstanceListeners(args);
  const clearListeners = (...args) => gMaps.event.clearListeners(...args);
  const addListener = (...args) => gMaps.event.addListener(...args);

  const createCircle = (...args) => new gMaps.Circle(...args);
  const { ControlPosition, SymbolPath } = gMaps;

  return {
    ControlPosition,
    createCircle,
    createMap,
    createLayer,
    createLatLngBounds,
    createLatLng,
    createMarker,
    clearInstanceListeners,
    clearListeners,
    addListener,
    SymbolPath
  };
};

export default createMapUtils;
