export default [
  {
    stylers: [
      {
        saturation: -50
      },
      {
        lightness: 10
      }
    ]
  },
  {
    featureType: 'administrative',
    stylers: [
      {
        saturation: 55
      }
    ]
  },
  {
    featureType: 'administrative',
    elementType: 'labels.text.fill',
    stylers: [
      {
        color: '#6c6c6c'
      },
      {
        visibility: 'on'
      },
      {
        weight: 1.5
      }
    ]
  },
  {
    featureType: 'poi',
    elementType: 'labels.icon',
    stylers: [
      {
        saturation: 20
      },
      {
        lightness: 5
      },
      {
        visibility: 'off'
      }
    ]
  },
  {
    featureType: 'poi',
    elementType: 'labels.text.fill',
    stylers: [
      {
        saturation: -80
      }
    ]
  },
  {
    featureType: 'poi.business',
    stylers: [
      {
        visibility: 'simplified'
      }
    ]
  },
  {
    featureType: 'poi.park',
    stylers: [
      {
        saturation: 10
      },
      {
        lightness: 5
      }
    ]
  },
  {
    featureType: 'road',
    stylers: [
      {
        saturation: 35
      },
      {
        lightness: 45
      }
    ]
  },
  {
    featureType: 'transit.line',
    stylers: [
      {
        saturation: -50
      }
    ]
  },
  {
    featureType: 'transit.station',
    elementType: 'labels.icon',
    stylers: [
      {
        saturation: 40
      }
    ]
  },
  {
    featureType: 'transit.station',
    elementType: 'labels.text.fill',
    stylers: [
      {
        color: '#00adff'
      },
      {
        saturation: -30
      },
      {
        visibility: 'on'
      }
    ]
  },
  {
    featureType: 'water',
    stylers: [
      {
        saturation: 80
      },
      {
        lightness: -5
      }
    ]
  }
];
